@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400");

html,
body {
  color: white !important;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  background-color: #2d3841;
}
