.col3 {
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  margin: 1%;
  flex: 1;
  min-width: 30%;
  max-width: 30%;
  position: relative;
}

.cardDesc {
  color: #cccccc;
  margin: 0;
  padding: 0.5em;
  cursor: default;
}

.img {
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.img:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media (max-width: 460px) {
  .col3 {
    max-width: 100%;
    margin: 0;
    margin-top: 1em;
  }
}
