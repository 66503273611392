.container {
  max-width: 960px;
  display: flex;
  margin: 1.5em auto;
  padding: 2em;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.portfolio {
  width: 100%;
  display: block;
  text-align: center;
  padding: 2em 1em;
}

.myImage {
  width: 200px;
  height: 200px;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 0 0.5em black;
  animation: fadeIn 0.7s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slag {
  margin: 0.3em;
  font-size: 1.5em;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s ease-out 0.7s forwards;
}

.typingClass {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  color: #ff6b6b;
  width: 28ch;
  opacity: 0;
  border-right: 1px solid greenyellow;
  font-family: "Courier New", Courier, monospace;
  font-weight: 300;
  animation: typing 2s steps(28) 2s forwards;
}

@keyframes typing {
  0% {
    width: 0ch;
    opacity: 1;
  }
  99% {
    border-right: 1px solid greenyellow;
  }
  100% {
    width: 28ch;
    opacity: 1;
    border: none;
  }
}

.desc {
  opacity: 0;
  animation: fadeIn 1s ease-out 1s forwards;
}

.highlight {
  color: #63b9ff;
}

.portfolioLink,
.github {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid rgba(99, 185, 255, 0.3);
  box-shadow: 0 0 0.1em black;
  display: flex;
  width: 50%;
  margin: 0.5em auto;
  padding: 0.5em 1em;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.github:hover,
.portfolioLink:hover {
  background: rgba(99, 185, 255, 0.5);
  color: rgba(0, 0, 0, 1);
}

.portfolioLink {
  animation: fadeIn 1s ease-in-out 1s forwards;
}
.github {
  animation: fadeIn 1s ease-in-out 1.3s forwards;
}
.gmail {
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s ease-in-out 1.6s forwards;
}

.codewars {
  opacity: 0;
  text-align: center;
  animation: fadeIn 1s ease-in-out 1.9s forwards;
}

.codewars img {
  min-width: 50%;
}

.githubSVG,
.gmailSVG {
  width: 30px;
  margin: 0 0.5em;
}

.link {
  text-decoration: none;
  color: #cccccc;
  margin: 0;
  padding: 0.8em 0.5em;
}

@media (max-width: 667px) {
  .link {
    font-size: 80%;
  }

  .container {
    padding: 1em;
  }

  .portfolio {
    width: 100%;
  }

  .github,
  .portfolioLink {
    width: 80%;
  }

  .codewars img {
    width: 100%;
  }
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.skillCard {
  border: 1px solid rgba(99, 185, 255, 0.3);
  border-radius: 10px;
  margin-right: 0.5em;
  margin-bottom: 1em;
  padding: 1em;
  max-width: 200px;
  text-align: center;
  box-shadow: 0 0 3px black;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: cardFadeIn 0.5s forwards;
}

@keyframes cardFadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
