@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400);
html,
body {
  color: white !important;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  background-color: #2d3841;
}

.Home_container__GHNWB {
  max-width: 960px;
  display: flex;
  margin: 1.5em auto;
  padding: 2em;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.Home_portfolio__3x9a7 {
  width: 100%;
  display: block;
  text-align: center;
  padding: 2em 1em;
}

.Home_myImage__2775f {
  width: 200px;
  height: 200px;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 0 0.5em black;
  -webkit-animation: Home_fadeIn__cYax0 0.7s ease-out forwards;
          animation: Home_fadeIn__cYax0 0.7s ease-out forwards;
}

@-webkit-keyframes Home_fadeIn__cYax0 {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Home_fadeIn__cYax0 {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Home_slag__M3xFI {
  margin: 0.3em;
  font-size: 1.5em;
  font-weight: 300;
  opacity: 0;
  -webkit-animation: Home_fadeIn__cYax0 1s ease-out 0.7s forwards;
          animation: Home_fadeIn__cYax0 1s ease-out 0.7s forwards;
}

.Home_typingClass__xbI1j {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  color: #ff6b6b;
  width: 28ch;
  opacity: 0;
  border-right: 1px solid greenyellow;
  font-family: "Courier New", Courier, monospace;
  font-weight: 300;
  -webkit-animation: Home_typing__2JFbn 2s steps(28) 2s forwards;
          animation: Home_typing__2JFbn 2s steps(28) 2s forwards;
}

@-webkit-keyframes Home_typing__2JFbn {
  0% {
    width: 0ch;
    opacity: 1;
  }
  99% {
    border-right: 1px solid greenyellow;
  }
  100% {
    width: 28ch;
    opacity: 1;
    border: none;
  }
}

@keyframes Home_typing__2JFbn {
  0% {
    width: 0ch;
    opacity: 1;
  }
  99% {
    border-right: 1px solid greenyellow;
  }
  100% {
    width: 28ch;
    opacity: 1;
    border: none;
  }
}

.Home_desc__3_qg_ {
  opacity: 0;
  -webkit-animation: Home_fadeIn__cYax0 1s ease-out 1s forwards;
          animation: Home_fadeIn__cYax0 1s ease-out 1s forwards;
}

.Home_highlight__3PgfY {
  color: #63b9ff;
}

.Home_portfolioLink__1FiD9,
.Home_github__1r5EY {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid rgba(99, 185, 255, 0.3);
  box-shadow: 0 0 0.1em black;
  display: flex;
  width: 50%;
  margin: 0.5em auto;
  padding: 0.5em 1em;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.Home_github__1r5EY:hover,
.Home_portfolioLink__1FiD9:hover {
  background: rgba(99, 185, 255, 0.5);
  color: rgba(0, 0, 0, 1);
}

.Home_portfolioLink__1FiD9 {
  -webkit-animation: Home_fadeIn__cYax0 1s ease-in-out 1s forwards;
          animation: Home_fadeIn__cYax0 1s ease-in-out 1s forwards;
}
.Home_github__1r5EY {
  -webkit-animation: Home_fadeIn__cYax0 1s ease-in-out 1.3s forwards;
          animation: Home_fadeIn__cYax0 1s ease-in-out 1.3s forwards;
}
.Home_gmail__nKEJw {
  text-align: center;
  opacity: 0;
  -webkit-animation: Home_fadeIn__cYax0 1s ease-in-out 1.6s forwards;
          animation: Home_fadeIn__cYax0 1s ease-in-out 1.6s forwards;
}

.Home_codewars__24JYv {
  opacity: 0;
  text-align: center;
  -webkit-animation: Home_fadeIn__cYax0 1s ease-in-out 1.9s forwards;
          animation: Home_fadeIn__cYax0 1s ease-in-out 1.9s forwards;
}

.Home_codewars__24JYv img {
  min-width: 50%;
}

.Home_githubSVG__2h1y4,
.Home_gmailSVG__Wr_WY {
  width: 30px;
  margin: 0 0.5em;
}

.Home_link__1snLs {
  text-decoration: none;
  color: #cccccc;
  margin: 0;
  padding: 0.8em 0.5em;
}

@media (max-width: 667px) {
  .Home_link__1snLs {
    font-size: 80%;
  }

  .Home_container__GHNWB {
    padding: 1em;
  }

  .Home_portfolio__3x9a7 {
    width: 100%;
  }

  .Home_github__1r5EY,
  .Home_portfolioLink__1FiD9 {
    width: 80%;
  }

  .Home_codewars__24JYv img {
    width: 100%;
  }
}

.Home_cardsContainer__1ucIo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.Home_skillCard__1wbR_ {
  border: 1px solid rgba(99, 185, 255, 0.3);
  border-radius: 10px;
  margin-right: 0.5em;
  margin-bottom: 1em;
  padding: 1em;
  max-width: 200px;
  text-align: center;
  box-shadow: 0 0 3px black;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-animation: Home_cardFadeIn__2ek2s 0.5s forwards;
          animation: Home_cardFadeIn__2ek2s 0.5s forwards;
}

@-webkit-keyframes Home_cardFadeIn__2ek2s {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes Home_cardFadeIn__2ek2s {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.Card_col3__3i6_x {
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  margin: 1%;
  flex: 1 1;
  min-width: 30%;
  max-width: 30%;
  position: relative;
}

.Card_cardDesc__1swns {
  color: #cccccc;
  margin: 0;
  padding: 0.5em;
  cursor: default;
}

.Card_img__1Vjf3 {
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.Card_img__1Vjf3:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media (max-width: 460px) {
  .Card_col3__3i6_x {
    max-width: 100%;
    margin: 0;
    margin-top: 1em;
  }
}

.Lang_container__2XipU {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  box-shadow: 0 0 0.3em black;
}

.Lang_header__1zELe {
  position: relative;
  padding: 5px 10px;
  margin: 0;
  padding-left: 50px;
  box-shadow: 0 0 1px black;
  font-weight: 300;
}

.Lang_header__1zELe img {
  width: 35px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px;
}

.Lang_body__2yiyQ {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

@media (max-width: 460px) {
  .Lang_body__2yiyQ {
    display: block;
  }
}

.Work_container__3fcQP {
  max-width: 960px;
  margin: 0 auto;
  margin-top: 1.5em;
  padding: 1em;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.Work_header__JNqSj {
  text-align: center;
}

.Work_gallery__3umim {
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Work_homeLink__1jdV7 {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid rgba(99, 185, 255, 0.3);
  box-shadow: 0 0 0.1em black;
  display: block;
  width: 100%;
  margin: 0.5em auto;
  box-sizing: border-box;
  padding: 0.5em 1em;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.Work_homeLink__1jdV7:hover {
  background: rgba(99, 185, 255, 0.5);
  color: rgba(0, 0, 0, 1);
}

h3 {
  margin: 0;
}

