.container {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  box-shadow: 0 0 0.3em black;
}

.header {
  position: relative;
  padding: 5px 10px;
  margin: 0;
  padding-left: 50px;
  box-shadow: 0 0 1px black;
  font-weight: 300;
}

.header img {
  width: 35px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px;
}

.body {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

@media (max-width: 460px) {
  .body {
    display: block;
  }
}
