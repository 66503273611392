.container {
  max-width: 960px;
  margin: 0 auto;
  margin-top: 1.5em;
  padding: 1em;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.header {
  text-align: center;
}

.gallery {
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.homeLink {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid rgba(99, 185, 255, 0.3);
  box-shadow: 0 0 0.1em black;
  display: block;
  width: 100%;
  margin: 0.5em auto;
  box-sizing: border-box;
  padding: 0.5em 1em;
  transition: all 0.3s ease-out;
}

.homeLink:hover {
  background: rgba(99, 185, 255, 0.5);
  color: rgba(0, 0, 0, 1);
}
